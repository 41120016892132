import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2162866b"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["textContent"]
const _hoisted_2 = ["value", "type", "placeholder", "required", "min", "max", "pattern", "disabled"]
const _hoisted_3 = {
  key: 0,
  class: "form__input-currency"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon = _resolveComponent("icon")!

  return (_openBlock(), _createElementBlock("label", {
    class: _normalizeClass(["form__input-component", { 'form__input--invalid': _ctx.isInvalid }])
  }, [
    _createElementVNode("div", {
      textContent: _toDisplayString(_ctx.label),
      class: "form__label"
    }, null, 8, _hoisted_1),
    _createElementVNode("div", {
      class: _normalizeClass(["form__input-wrap", { 'form__input-wrap--disabled': _ctx.disabled }])
    }, [
      _createElementVNode("input", {
        class: "form__input",
        value: _ctx.modelValue,
        type: _ctx.type,
        placeholder: _ctx.placeholder,
        required: _ctx.required,
        min: _ctx.min,
        max: _ctx.max,
        pattern: _ctx.pattern,
        disabled: _ctx.disabled,
        ref: "input",
        onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onInput && _ctx.onInput(...args)))
      }, null, 40, _hoisted_2),
      (_ctx.currency)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.currency), 1))
        : _createCommentVNode("", true),
      (_ctx.clearBtn && !_ctx.disabled)
        ? _withDirectives((_openBlock(), _createElementBlock("button", {
            key: 1,
            type: "button",
            class: "form__input-clear btn btn--icon",
            tabindex: "-1",
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onClear && _ctx.onClear(...args)))
          }, [
            _createVNode(_component_icon, {
              name: "close",
              title: "close"
            })
          ], 512)), [
            [_vShow, _ctx.modelValue]
          ])
        : _createCommentVNode("", true)
    ], 2)
  ], 2))
}