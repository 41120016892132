
import { defineComponent } from 'vue';
import Icon from '@/components/Icon.vue';

export default defineComponent({
  name: 'FormInput',
  components: { Icon },
  props: {
    modelValue: { type: [String, Number], required: false, default: '' },
    label: { type: String, required: false },
    type: { type: String, default: 'text' },
    placeholder: { type: String, required: false },
    clearBtn: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    min: { type: Number, default: undefined },
    max: { type: Number, default: undefined },
    currency: { type: String, default: null },
    pattern: { type: String, default: null },
    disabled: { type: Boolean, default: false },
  },
  data() {
    return {
      isInvalid: false,
    };
  },
  computed: {
    value: {
      get(): string | number {
        return this.modelValue;
      },
      set(value: string): void {
        // console.log(e)
        // this.isInvalid = !this.$refs?.input?.checkValidity()
        this.$emit('update:modelValue', value);
      },
    },
  },

  methods: {
    onInput(e: {
      target: { checkValidity: () => unknown; value: string };
    }): void {
      this.isInvalid = !e.target?.checkValidity();
      this.$emit('update:modelValue', e.target.value);
    },
    onClear(): void {
      this.value = '';
      (this.$refs.input as HTMLInputElement).focus();
    },
  },
});
