
import FormInput from '@/components/FormInput.vue';
import Icon from '@/components/Icon.vue';
import { defineComponent, ref, computed } from 'vue';
import { useStore } from 'vuex';
import { IAllocationRequest, TWalletType } from '@/types/user';
import CONSTS from '@/utils/constants';

const CURRENCY = 'USDT';

export default defineComponent({
  name: 'AllocationRequest',
  components: { FormInput, Icon },
  setup() {
    const store = useStore();
    const requestedAllocation = computed((): IAllocationRequest => {
      return store.getters['user/allocation'];
    });
    const MIN_AMOUNT = computed<number>(
      () => store.state.app.settings.allocation.minAmount
    );
    const MAX_AMOUNT = computed<number>(
      () => store.state.app.settings.allocation.maxAmount
    );
    const AMOUNT_PLACEHOLDER = computed<string>(
      () => `(${MIN_AMOUNT.value} – ${MAX_AMOUNT.value})`
    );
    const BLOCKCHAINS_DATA = CONSTS.BLOCKCHAINS_DATA
    const busy = ref(false);
    const amount = ref<number>(requestedAllocation.value?.maxInvestedAmount);
    const address = ref<string>(requestedAllocation.value?.walletAddress || '');
    const activeChain = ref<TWalletType | '' | null>(
      requestedAllocation.value?.walletType || null
    );
    const userStates = CONSTS.USER_STATES;
    const isChainValid = computed((): boolean | null => {
      if (activeChain.value === null) return null;
      return !!activeChain.value;
    });
    const isAlcRequested = computed<boolean>(
      () =>
        store.getters['user/currentState'] === userStates.ALLOCATION_REQUESTED
    );
    const isAlcNotAlc = computed<boolean>(
      () =>
        store.getters['user/currentState'] ===
        userStates.ALLOCATION_NO_ALLOCATION
    );
    const isDisabledForm = computed<boolean>(
      () => !!(busy.value || isAlcRequested.value || isAlcNotAlc.value)
    );
    const submit = () => {
      if (activeChain.value === null) {
        activeChain.value = '';
        return false;
      }
      if (activeChain.value) {
        busy.value = true;
        store
          .dispatch('user/allocationRequest', <IAllocationRequest>{
            maxInvestedAmount: amount.value,
            walletType: activeChain.value,
            walletAddress: address.value,
          })
          .then((res: IAllocationRequest) => {
            console.log('user/allocationRequest ok', res);
          })
          .catch((e) => {
            console.log('user/allocationRequest failed', e);
          })
          .finally(() => {
            busy.value = false;
          });
      }
    };
    return {
      busy,
      amount,
      submit,
      address,
      CURRENCY,
      MIN_AMOUNT,
      MAX_AMOUNT,
      activeChain,
      isChainValid,
      isDisabledForm,
      isAlcRequested,
      BLOCKCHAINS_DATA,
      AMOUNT_PLACEHOLDER,
    };
  },
});
