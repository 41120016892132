
import AllocationRequest from '@/components/AllocationRequest.vue';
import AllocationConfirmedBanner from '@/components/AllocationConfirmedBanner.vue';
import Banner from '@/components/Banner.vue';
import { defineComponent, ref, computed } from 'vue';
import { useStore } from 'vuex';
import { IAllocationRequest, TWalletType } from '@/types/user';
import CONSTS from '@/utils/constants';

const CURRENCY = 'USDT';
interface IBlockChain {
  type: TWalletType;
  name: string;
}
const BLOCKCHAINS: IBlockChain[] = [
  {
    type: 'ethereum',
    name: 'Ethereum (ETH)',
  },
  {
    type: 'binance-smart-chain',
    name: 'Binance Smart Chain (BSC)',
  },
];

export default defineComponent({
  name: 'Allocation',
  components: { Banner, AllocationRequest, AllocationConfirmedBanner },
  setup() {
    const store = useStore();
    const requestedAllocation = computed((): IAllocationRequest => {
      return store.getters['user/allocation'];
    });
    const MIN_AMOUNT = computed<number>(
      () => store.state.app.settings.allocation.minAmount
    );
    const MAX_AMOUNT = computed<number>(
      () => store.state.app.settings.allocation.maxAmount
    );
    const AMOUNT_PLACEHOLDER = computed<string>(
      () => `(${MIN_AMOUNT.value} – ${MAX_AMOUNT.value})`
    );
    const busy = ref(false);
    const amount = ref<number>(requestedAllocation.value?.maxInvestedAmount);
    const address = ref<string>(requestedAllocation.value?.walletAddress || '');
    const activeChain = ref<TWalletType | '' | null>(
      requestedAllocation.value?.walletType || null
    );
    const userStates = CONSTS.USER_STATES;
    const curState = computed(() => store.getters['user/currentState']);
    const isChainValid = computed((): boolean | null => {
      if (activeChain.value === null) return null;
      return !!activeChain.value;
    });
    const isAlcNotRequested = computed<boolean>(
      () => curState.value === userStates.ALLOCATION_NOT_REQUESTED
    );
    const isKycSuccess = computed<boolean>(
      () => curState.value === userStates.KYC_SUCCESS
    );
    const isAlcRequested = computed<boolean>(
      () => curState.value === userStates.ALLOCATION_REQUESTED
    );
    const isAlcNotAlc = computed<boolean>(
      () => curState.value === userStates.ALLOCATION_NO_ALLOCATION
    );
    const isAlcConfirmed = computed<boolean>(
      () => curState.value === userStates.ALLOCATION_CONFIRMED
    );
    const isDisabledForm = computed<boolean>(
      () => !!(busy.value || isAlcRequested.value || isAlcNotAlc.value)
    );
    return {
      busy,
      store,
      CONSTS,
      amount,
      address,
      CURRENCY,
      MIN_AMOUNT,
      MAX_AMOUNT,
      isAlcNotAlc,
      activeChain,
      BLOCKCHAINS,
      isChainValid,
      isKycSuccess,
      isAlcConfirmed,
      isDisabledForm,
      isAlcRequested,
      isAlcNotRequested,
      AMOUNT_PLACEHOLDER,
    };
  },
});
